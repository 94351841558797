.event-avg {
    color: rgb(43, 82, 100);
    margin: 10px;
    padding: 5px;
}

.event-name {
    color: rgb(43, 82, 100);
}

.ticket{
    display: flex;
    justify-content: space-between;
    padding: 5px;
}

.ticket h3 {
    flex: 1;
}

.ticket input {
    flex: 3;
    font-size: larger;
    border: 1px solid rgb(43, 82, 100);
    border-radius: 5px;
}